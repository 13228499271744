import styled from 'styled-components';

const Container = styled.table`
  width: 100%;
  overflow: hidden;
  border-collapse: separate;
  border-spacing: 0;
  ${props => props.theme.typeBlock.table}
`;

const Tr = styled.tr`
  background-color: ${ props => props.isHeader?'#c3b6b2':'none'};
  color: ${ props => props.theme.color.white};
`;

const Td = styled.td.attrs((props) => {
    if (props.html) {
        return {
            dangerouslySetInnerHTML: {__html: props.html}
        }
    }
})`
  background-color: ${ props => props.isHighlight?props.theme.color.pinkOrange:'none'};
  text-align: center;
  height: ${ props => props.height?props.height:'40px' };
  @media screen and (max-width: 800px) {
    width: 25%; /* 48px를 25%로 수정.. table-layout:fixed가 아닌상태에서 너비 동일하게 처리  20220816 */
    height: 24px;
    white-space: pre-line;
  }
 `;

function Table({data}){
  return(
    <Container>
      {
        data.map((row,i) => 
          <Tr
            key={i}
            isHeader={i===0}
          >
            {
              row.map((col,j) =>
                <Td
                  isHighlight={col.isHighlight}
                  colSpan={col.colspan}
                  key={j}
                  height={col.height}
                  html={col.html}
                >
                  {col.value}
                </Td>
              )
            }
          </Tr>
        )
      }
    </Container>
  )
}

export default Table;