
import styled from 'styled-components';

const Container = styled.div`
  cursor: default;
  margin-bottom:10px;
  box-sizing: border-box;
  
  p {
    background-color:#f5f3f3;
    text-align:center;
    padding:10px;
    min-height:64px;
    margin:0;
    color:#855152;
    span {line-height:1.4em;padding:0;margin:0;display:block;}
    span:first-child {font-size:0.9em;}
  }
  
  figure, figcaption {display:block;padding:0;margin:0;}
  figure {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    background: #000;

    img {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 220px;
      box-sizing: border-box;
    }
  }
  figcaption {position:absolute;bottom:20px;width:100%;text-align:center;line-height:1.4em;z-index:1;padding:0 10px 0}
  @media screen and (max-width: 800px) {
    figcaption {font-size:0.9em;bottom:10px;}
  }
`;

function CardImage({
       data
    }) {
    return (
        <Container>
            <p>
                <span>{data.txt1}</span>
                <span>{data.txt2}</span>
            </p>
            <figure>
                <img src={data.img} alt="" />
                <figcaption>{data.caption}</figcaption>
            </figure>
        </Container>
    )
}

export default CardImage;
